
.pageblock[data-v-51f12bd8] {
  text-align: right;
  margin-top: 5px;
}
.lockScreen[data-v-51f12bd8] {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}
